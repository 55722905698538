import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
import Head from "../parts/head.js";
import Footer from "../parts/footer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
   import { Redirect } from "react-router-dom";
  
function importLink(r) {
  return r.keys().map(r);
}
 
const imagesLink = importLink(require.context("../ba-pdf/", false, /\.(pdf)$/)); 
 


class Stundenbericht extends Component {
  constructor() {
    super();
    this.state = {
        anweisungen:[]
    };
  }
  componentDidMount = () => {
         document.title = "Betriebsanweisungen - Seyband Stunden App";
    };
 
   createName = (item) => {
     let newName = item;
     newName = item.replace("/static/media/", "");
      newName = newName.substring(0, newName.indexOf("."));
    return newName;
    }
  render() {
        if (sessionStorage.getItem("userData")) {
          return (
            <div>
              <Head disableLogin={false} />
              <section className="betriebsanweisung">
                <div className="menu-links text-center">
                  <h1>
                    <FontAwesomeIcon icon={faFilePdf} />
                    &nbsp;Betriebsanweisungen
                  </h1>
                </div>
                <div className="container">
                  <div className="row">
                    {imagesLink.map((link, i) => {
                      return (
                        <div key={i} className="col-md-4">
                          <a href={link} rel="noopener noreferrer" target="_blank">
                            <button className="btn-beriebsanweisung">{this.createName(link)}</button>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
              <Footer />
            </div>
          );
        } else {
          return <Redirect to={"/"} />;
        }
  }
}
export default Stundenbericht;
