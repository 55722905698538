import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faExclamationTriangle,
  faTrash,
  faEdit,
  faSave,
  faTimesCircle 
} from "@fortawesome/free-solid-svg-icons";
import BaseUrl from "../config/config.js";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalBody } from "reactstrap";
registerLocale("de", de);

var $ = require("jquery");

class AdminModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableInput: true,
      id: this.props.id,
      vorname: this.props.vorname.charAt(0).toUpperCase() + this.props.vorname.slice(1),
      nachname: this.props.nachname.charAt(0).toUpperCase() + this.props.nachname.slice(1),
      email: this.props.email,
      sendMail: this.props.sendMail === "1" ? true : false,
      showPw: false,
      showPwCheck: false,
      changePassword: false,
      password: "",
      passwordCheck: "",
      errorPwCheck: false,
      successChange: false,
      update: true,
      modalDelete: false,
      hideModal: false,
      errorAlreadySend: false,
      errorMessage: false,
      sendResetPw: false,
      deleteMessage:false
    };
  }
  componentDidMount = () => {};

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
        updateSuccess: false,
      });
    }
    if (e.target.name === "changePassword") {
      this.setState({
        newPassword: "",
        oldPassword: "",
      });
    }
  };

  createName = () => {
    if (this.state.vorname && this.state.nachname !== "") {
      return this.state.vorname.toLowerCase() + "_" + this.state.nachname.toLowerCase();
    } else {
      return this.state.vorname.toLowerCase();
    }
  };
  updateMitarbeiter = (e) => {
    e.preventDefault();

    let updateMitarbeiter = {};

    updateMitarbeiter = {
      id: this.state.id,
      name: this.createName(),
      email: this.state.email,
      sendMail: this.state.sendMail ? 1 : 0,
      sendResetPw: this.state.sendResetPw,
    };
    $.ajax({
      url: BaseUrl + "api-seyband-stunden-app/admin/updateMitarbeiter.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(updateMitarbeiter),
      success: function (response) {
        this.setState({
          successChange: true,
        });
        if(this.state.sendResetPw){
           this.generateResetPassword(this.createName(), this.state.email);
        }
         this.props.refreshArray(this.state.id, this.state.vorname, this.state.nachname, this.state.email, this.state.sendMail);
 
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  generateResetPassword = (name, email) => {
    let data = {
      name: name,
      email: email,
    };
    $.ajax({
      url: BaseUrl + "api-seyband-stunden-app/user/generatePasswordReset.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(data),
      success: function (response) {
        if (response === "already send") {
          this.setState({
            errorAlreadySend: true,
            errorMessage: false,
            resetSuccess: false,
          });
        } else {
          this.setState({
            errorAlreadySend: false,
            errorMessage: false,
            resetSuccess: true,
          });
        }
        setTimeout(
          function () {
            this.props.toggle();
          }.bind(this),
          4500
        );
       }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  deleteMitarbeiter = () => {
    let deleteMitarbeiter = {
      id: this.state.id,
      name: this.createName(),
    };
    $.ajax({
      url: BaseUrl + "api-seyband-stunden-app/admin/deleteMitarbeiter.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(deleteMitarbeiter),
      success: function (response) {
        this.setState({
          deleteMessage: true,
        });
        this.props.refreshArrayDelte(this.state.id);
      setTimeout(
          function () {
            this.props.toggle();
          }.bind(this),
          2500
        );  
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  clickShowPw = (e) => {
    e.preventDefault();
    this.setState({
      showPw: !this.state.showPw,
    });
  };
  clickShowPwCheck = (e) => {
    e.preventDefault();
    this.setState({
      clickShowPwCheck: !this.state.clickShowPwCheck,
    });
  };
  askDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
      hideModal: !this.state.hideModal,
    });
  };
  render() {
    return (
      <div>
        <Modal className={this.state.hideModal ? "hide-modal" : "show-modal"} toggle={this.props.toggle} isOpen={this.props.modal}>
          <ModalBody>
            <div className="modal-header">
              <h3>
                <FontAwesomeIcon icon={faEdit} />
                &nbsp; Mitarbeiter bearbeiten?
              </h3>
              <button className="close" onClick={this.props.toggle}>
                {" "}
                <FontAwesomeIcon icon={faTimesCircle} />
              </button>
            </div>
            <div className="modal-admin">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-12">
                  <div className="absolute-p">
                    <p>Vorname</p>
                    <input disabled value={this.state.vorname} name="vorname" type="text" onChange={this.onChange} />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                  <div className="absolute-p">
                    <p>Nachname</p>
                    <input disabled value={this.state.nachname} name="nachname" type="text" onChange={this.onChange} />
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="absolute-p">
                    <p>Emailadresse</p>
                    <input value={this.state.email} name="email" type="email" onChange={this.onChange} />
                  </div>
                </div>
                <div className="col-12">
                  <label>
                    <input type="checkbox" name="sendMail" onChange={this.onChange} checked={this.state.sendMail} />
                    Stundenbericht an Emailadresse senden?
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    <input type="checkbox" name="sendResetPw" onChange={this.onChange} checked={this.state.sendResetPw} />
                    Email für das Zurücksetzen des Passwortes senden?
                  </label>
                </div>
                <div className="col-12">
                  <div className=" modal-admin-border-top"></div>
                  <div className={this.state.successChange ? "show success" : "hide"}>
                    <FontAwesomeIcon icon={faCheckSquare} />
                    &nbsp; Die Daten wurden geändert!
                  </div>
                  <div className={this.state.errorAlreadySend ? "show error" : "hide"}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    &nbsp; Eine Email zum Zurücksetzen des Passworts wurde bereits an diese Email verschickt.
                  </div>
                  <div className={this.state.resetSuccess ? "show success" : "hide"}>
                    <FontAwesomeIcon icon={faCheckSquare} />
                    &nbsp; Die Email zum zurücksetzen des Passwortes wurde an die Emailadresse geschickt.
                  </div>
                  <div className={this.state.errorMessage ? "show error" : "hide"}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    &nbsp; Der Benutzer und die Emailadresse stimmen nicht überein!
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-2 col-xs-3">
                  <button onClick={this.askDelete} className="abb-button">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
                <div className="col-md-10 col-sm-10 col-xs-9">
                  <button onClick={this.updateMitarbeiter} className="save-button">
                    Ändern <FontAwesomeIcon icon={faSave} />
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal toggle={this.askDelete} isOpen={this.state.modalDelete}>
          <ModalBody>
            <div className="modal-header">
              <h3>
                <FontAwesomeIcon icon={faTrash} />
                &nbsp; {this.state.vorname} {this.state.nachname} löschen?
              </h3>
              <button className="close" onClick={this.askDelete}>
                {" "}
                <FontAwesomeIcon icon={faTimesCircle} />
              </button>
            </div>
            <div className="stunden-input">
              <div className="row">
                <div className="col-12">
                <div className={this.state.deleteMessage ? "show success text-center" : "hide"}>
                  <FontAwesomeIcon icon={faCheckSquare} />
                  &nbsp; Der Mitarbeiter wurde gelöscht!
                </div>
                </div>
                <div className="col-6">
                  <button onClick={this.askDelete} className="save-button">
                    <FontAwesomeIcon icon={faTimesCircle} />
                    &nbsp;Abbrechen
                  </button>
                </div>
                <div className="col-6">
                  <button onClick={this.deleteMitarbeiter} className="abb-button">
                    <FontAwesomeIcon icon={faTrash} /> &nbsp; Löschen
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default AdminModal;
