import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
import Head from "../parts/head.js";
import Footer from "../parts/footer.js";
import StundenberichtListe from "../stundenbericht/stundenberichtListe.js";
import SendModal from "../stundenbericht/sendModal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserClock, faListUl, faSave, faPaperPlane, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import BaseUrl from "../config/config.js";
import { registerLocale } from "react-datepicker";
import moment from "moment";
import { Redirect } from "react-router-dom";

registerLocale("de", de);
var $ = require("jquery");

class Stundenbericht extends Component {
  constructor() {
    super();
    this.state = {
      date: "",
      start: "",
      end: "",
      pause: "",
      bezStd: "Keine",
      fahrer: "nein",
      baustelle: "",
      baustelleBv: "",
      bvId: "",
      baustelleBvSave: "",
      baustelleSave: "",
      stunden: [],
      successSend: false,
      failSend: false,
      disableInput: false,
      errorInput: false,
      bv: [],
      emptyRequired: [],
      inputNames: ["date", "start", "end", "pause", "baustelle"],
      inputNamesTranslate: { date: "Datum", start: "Beginn", end: "Ende", pause: "Pause", baustelle: "Baustelle" },
      toggle: false,
    };
  }
  componentDidMount = () => {
    document.title = "Stundenbericht - Seyband Stunden App";
    if (sessionStorage.getItem("userData")) {
      Promise.all([this.creatName()]).then(() => {
        this.loadStunden(JSON.parse(sessionStorage.getItem("userData")).userData.name);
        this.loadBvKlr();
      });
    }
  };
  creatName = () => {
    let name = JSON.parse(sessionStorage.getItem("userData"));
    name = name.userData.name.split("_");
    let fName = name[0].charAt(0).toUpperCase() + name[0].slice(1);
    let lName = name[1].charAt(0).toUpperCase() + name[1].slice(1);
    this.setState({
      firstname: fName,
      lastname: lName,
    });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "bezStd" && e.target.value !== "Keine") {
      this.setState({
        start: "-",
        end: "-",
        pause: "-",
        baustelle: "-",
        disableInput: true,
      });
    } else if (e.target.name === "bezStd" && e.target.value === "Keine") {
      this.setState({
        start: "",
        end: "",
        pause: "",
        baustelle: "",
        disableInput: false,
      });
    } else if (e.target.name === "baustelle" && e.target.value !== "") {
      this.setState({
        baustelleBvSave: this.state.baustelleBv !== "" ? this.state.baustelleBv : this.state.baustelleBvSave,
        baustelleBv: "",
      });
    } else if (e.target.name === "baustelle" && e.target.value === "") {
      this.setState({
        baustelleBv: this.state.baustelleBvSave,
      });
    } else if (e.target.name === "baustelleBv" && e.target.value !== "") {
      this.setState({
        baustelleSave: this.state.baustelle !== "" ? this.state.baustelle : this.state.baustelleSave,
        baustelle: "",
      });
    } else if (e.target.name === "baustelleBv" && e.target.value === "") {
      this.setState({
        baustelle: this.state.baustelleSave,
      });
    }
  };
  handleDate = (d) => {
    this.setState({
      date: d,
    });
  };
  loadStunden = (name) => {
    return fetch(BaseUrl + "api-seyband-stunden-app/stundenbericht/readStunden.php?name=" + name.toLocaleLowerCase())
      .then((response) => response.json())
      .then((responseJson) => {
        return this.setState({
          stunden: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  loadBvKlr = () => {
    return fetch(BaseUrl + "api-seyband-stunden-app/stundenbericht/readBvKlr.php")
      .then((response) => response.json())
      .then((responseJson) => {
        return this.setState({
          bv: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  loadStundenLast = (name) => {
    return fetch(BaseUrl + "api-seyband-stunden-app/stundenbericht/readStundenLast.php?name=" + name)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          stunden: [...this.state.stunden, responseJson.records[0][0]],
        });
      })
      .catch((error) => {});
  };
  handleValidation = () => {
    let helpState = [
      {
        date: this.state.date,
        start: this.state.start,
        end: this.state.end,
        pause: this.state.pause,
        baustelle: this.state.baustelle ? this.state.baustelle : this.state.baustelleBv,
      },
    ];
    this.state.inputNames.map((name, i) => {
      if (!helpState[0][name]) {
        this.state.emptyRequired = [...this.state.emptyRequired, this.state.inputNamesTranslate[name]];
      }
    });
    if (this.state.emptyRequired.length > 0) {
      this.setState({
        errorInput: true,
      });
      return false;
    } else {
      return true;
    }
  };
  resetInputFields = () => {
    this.setState({
      errorInput: false,
      emptyRequired: [],
      errorBv: false,
    });
  };
  save = (e) => {
    e.preventDefault();
    Promise.all([this.resetInputFields()]).then(() => {
      if (this.handleValidation()) {
        let combineNames = this.state.firstname + "_" + this.state.lastname;
        if (this.state.baustelle !== "" && this.state.baustelleBv !== "") {
          this.setState({
            errorBv: true,
          });
        } else {
          let stunden = {
            name: combineNames.toLocaleLowerCase(),
            date: moment(this.state.date).format("YYYY-MM-DD"),
            start: this.state.start,
            end: this.state.end,
            pause: this.state.pause,
            bezStd: this.state.bezStd,
            fahrer: this.state.fahrer,
            baustelle: this.state.baustelle !== "" ? this.state.baustelle : this.state.baustelleBv,
          };
          $.ajax({
            url: BaseUrl + "api-seyband-stunden-app/stundenbericht/saveStundenbericht.php",
            type: "POST",
            dataType: "text",
            data: JSON.stringify(stunden),
            success: function (response) {
              this.setState({
                date: "",
                start: "",
                end: "",
                pause: "",
                bezStd: "",
                fahrer: "",
                baustelle: "",
                baustelleBv: "",
                errorBv: false,
                disableInput: false,
              });
              this.loadStundenLast(combineNames.toLocaleLowerCase());
            }.bind(this),
            error: function (xhr, resp, text) {},
          });
        }
      }
    });
  };
  changeStunden = (object) => {
    let help = [];
     help = this.state.stunden;
    this.setState({
      stunden: help.map((item) => (item.id === object.id ? { help, ...object } : { help, ...item })),
    });
  };
  send = () => {
    let combineNames = this.state.firstname + "_" + this.state.lastname;

    let stunden = {
      name: combineNames.toLocaleLowerCase(),
    };
    $.ajax({
      url: BaseUrl + "api-seyband-stunden-app/stundenbericht/saveAndSendCSV.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(stunden),
      success: function (response) {
        if (response === "gesendet") {
          this.setState({
            stunden: [],
            modal: !this.state.modal,
          });
          this.showSuccessSend("successSend");
        } else if (response === "leer") {
          this.showSuccessSend("failSend");
        }
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  showSuccessSend = (what) => {
    this.setState({
      [what]: true,
    });
    setTimeout(() => {
      this.setState({
        [what]: false,
      });
    }, 8000);
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  render() {
    if (sessionStorage.getItem("userData")) {
      return (
        <div>
          <Head disableLogin={false} />
          <section className="stundenbericht">
            <div className="menu-links text-center">
              <h1>
                <FontAwesomeIcon icon={faUserClock} /> Stundenbericht
              </h1>
              <h2>
                - {this.state.firstname} {this.state.lastname} -
              </h2>
            </div>
            <div className="container">
              <form onSubmit={this.save}>
                <div className="stunden-input">
                  <div className="row  ">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <div className="absolute-p">
                        <p>Datum</p>
                        <DatePicker
                          name="date"
                          selected={this.state.date}
                          onChange={this.handleDate}
                          dateFormat="dd.MM.yyyy"
                          locale={de}
                          withPortal
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-6">
                      <div className="absolute-p">
                        <p>Beginn</p>
                        <input disabled={this.state.disableInput} value={this.state.start} name="start" type="time" onChange={this.onChange} />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-6">
                      <div className="absolute-p">
                        <p>Ende</p>
                        <input disabled={this.state.disableInput} value={this.state.end} name="end" type="time" onChange={this.onChange} />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-6">
                      <div className="absolute-p">
                        <p>Pause</p>
                        <input disabled={this.state.disableInput} value={this.state.pause} name="pause" type="time" onChange={this.onChange} />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-6">
                      <div className="absolute-p">
                        <p>Bez. Std.</p>
                        <select value={this.state.bezStd} onChange={this.onChange} name="bezStd">
                          <option value="Keine">Keine</option>
                          <option value="Urlaub">Urlaub</option>
                          <option value="Feiertag">Feiertag</option>
                          <option value="Krank">Krank</option>
                          <option value="Kurzarbeit">Kurzarbeit</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-6">
                      <div className="absolute-p">
                        <p>Fahrer</p>
                        <select disabled={this.state.disableInput} value={this.state.fahrer} onChange={this.onChange} name="fahrer">
                          <option value="firmen-Kfz">firmen-Kfz</option>
                          <option value="privat-Kfz">privat-Kfz</option>
                          <option value="nein">Nein</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 col-xs-12 col-sm-12">
                      <div className="absolute-p">
                        <p>Baustelle</p>
                        <input
                          disabled={this.state.disableInput}
                          value={this.state.baustelle}
                          onChange={this.onChange}
                          name="baustelle"
                          type="text"
                        />
                      </div>
                      <div className="absolute-p">
                        <p>akutelle Baustellen</p>

                        <select disabled={this.state.disableInput} value={this.state.baustelleBv} onChange={this.onChange} name="baustelleBv">
                          <option value="">-</option>
                          {this.state.bv.map((bv, i) => (
                            <option key={i} value={bv.bv}>
                              {bv.bv}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className={this.state.errorBv ? "col-12 show error " : "hide"}>
                      <p>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        &nbsp; Bitte nur eine Baustelle ausfüllen!
                      </p>
                    </div>
                    <div className={this.state.errorInput ? "col-12 show error " : "hide"}>
                      {this.state.emptyRequired.map((name, i) => {
                        return (
                          <p key={i}>
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            &nbsp;
                            <b>{name}</b> darf nicht leer sein!
                          </p>
                        );
                      })}
                    </div>
                    <div className="col-12">
                      <button disabled={this.state.date ? false : true} type="submit" className="save-button">
                        Speichern <FontAwesomeIcon icon={faSave} />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="stundenListe">
                <h2>
                  <FontAwesomeIcon icon={faListUl} /> Stundenübersicht
                </h2>
                <p className={this.state.stunden.length > 0 ? "hide" : "show text-center"}>Die Stundenliste ist leer!</p>
                <StundenberichtListe bv={this.state.bv} changeStunden={this.changeStunden} stunden={this.state.stunden} />
              </div>
              <div className="text-center"></div>
              <div className="row">
                <div className="stundenListe col-12">
                  <button disabled={this.state.stunden.length > 0 ? false : true} className="btn-send" onClick={this.toggle}>
                    Liste Abschicken <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>
            </div>
          </section>
          {this.state.modal ? <SendModal modal={this.state.modal} toggle={this.toggle} send={this.send} /> : ""}
          <Footer />
        </div>
      );
    } else {
      return <Redirect to={"/"} />;
    }
  }
}
export default Stundenbericht;
