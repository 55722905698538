import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
class Footer extends Component {
 

  render() {
    return (
      <footer>
        <div className="footer">© {new Date().getFullYear()} Seyband Trockenbau GmbH & CO. KG</div>
      </footer>
    );
  }
}
export default Footer;
