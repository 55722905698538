import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTimesCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import BaseUrl from "../config/config.js";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
registerLocale("de", de);

var $ = require("jquery");

class StundenberichtModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      date: this.props.date,
      start: this.props.start,
      end: this.props.end,
      pause: this.props.pause,
      bezStd: this.props.bezStd,
      fahrer: this.props.fahrer,
      baustelle: this.props.baustelle,
      baustelleSave: "",
      baustelleBvSave: "",
      disableInput: false,
      errorInput: false,
      emptyRequired: [],
      inputNames: ["date", "start", "end", "pause", "baustelle"],
      inputNamesTranslate: { date: "Datum", start: "Beginn", end: "Ende", pause: "Pause", baustelle: "Baustelle" },
      savedFields: { dateSave: "", startSave: "", endSave: "", pauseSave: "", baustelleSave: "" },
      baustelleBv: "",
    };
  }
  componentDidMount = () => {
    this.checkBvKlr();
    if (this.props.bezStd !== "Keine") {
      this.setState({
        disableInput: true,
      });
    }
  };
  checkBvKlr = () => {
    let helpFindBv ="";
    this.props.bv.find((name) => {
       if (name.bv === this.state.baustelle) {
         helpFindBv = name;
       }
    });
    if (helpFindBv) {
      this.setState({
        baustelleBv: this.state.baustelle,
        baustelle: "",
      });
    } else {
      this.setState({
        baustelle: this.state.baustelle,
        baustelleBv: "",
      });
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === "bezStd" && e.target.value !== "Keine") {
      Promise.all([this.saveFields(), this.emptyFields()]).then(() => {
        this.disableInput();
        this.setState({
          errorInput: false,
        });
      });
    } else if (e.target.name === "bezStd" && e.target.value === "Keine") {
      this.setState({
        start: this.state.savedFields.startSave,
        end: this.state.savedFields.endSave,
        pause: this.state.savedFields.pauseSave,
        baustelle: this.state.savedFields.baustelleSave,
        baustelleBv: this.state.savedFields.baustelleBvSave,
        disableInput: false,
      });
    } else if (e.target.name === "baustelle" && e.target.value !== "") {
      this.setState({
        baustelleBvSave: this.state.baustelleBv !== "-" ? this.state.baustelleBv : this.state.baustelleBvSave,
        baustelleBv: "",
      });
    } else if (e.target.name === "baustelle" && e.target.value === "") {
      this.setState({
        baustelleBv: this.state.baustelleBvSave,
      });
    } else if (e.target.name === "baustelleBv" && e.target.value !== "") {
      this.setState({
        baustelleSave: this.state.baustelle !== "" ? this.state.baustelle : this.state.baustelleSave,
        baustelle: "",
      });
    } else if (e.target.name === "baustelleBv" && e.target.value === "") {
      this.setState({
        baustelle: this.state.baustelleSave,
      });
    }
  };

  disableInput = () => {
    this.setState({
      disableInput: true,
    });
  };
  emptyFields = () => {
    this.setState({
      start: "",
      end: "",
      pause: "",
      baustelle: "",
      baustelleBv: "",
    });
  };
  saveFields = () => {
    this.state.savedFields = {
      dateSave: this.state.date,
      startSave: this.state.start,
      endSave: this.state.end,
      pauseSave: this.state.pause,
      baustelleSave: this.state.baustelle,
      baustelleBvSave: this.state.baustelleBv
    };
  };
  handleDate = (d) => {
    this.setState({
      date: d,
    });
  };
  handleValidation = () => {
    if(this.state.bezStd === "Keine"){
      let helpState = [
        {
          date: this.state.date,
          start: this.state.start,
          end: this.state.end,
          pause: this.state.pause,
          baustelle: this.state.baustelle ? this.state.baustelle : this.state.baustelleBv,
        },
      ];
      this.state.inputNames.map((name, i) => {
        if (!helpState[0][name]) {
          this.state.emptyRequired = [...this.state.emptyRequired, this.state.inputNamesTranslate[name]];
        }
      });
      if (this.state.emptyRequired.length > 0) {
        this.setState({
          errorInput: true,
        });
        return false;
      } else {
        return true;
      }
    }
    else{
      return true;
    }
  };
  resetInputFields = () => {
    this.setState({
      errorInput: false,
      emptyRequired: [],
    });
  };
  checkBaustelle = () =>{
    if(this.state.baustelle){
       return this.state.baustelle;
    }
    else if(this.state.baustelleBv){
      return this.state.baustelleBv;
    }
    else{
      return "";
    }
  }
  updateStundenbericht = (e) => {
    e.preventDefault();
    Promise.all([this.resetInputFields()]).then(() => {
      if (this.handleValidation()) {
        if (this.state.baustelle !== "" && this.state.baustelleBv !== "") {
          this.setState({
            errorBv: true,
          });
        } else {
          let stundenUpdate = {
            name: JSON.parse(sessionStorage.getItem("userData")).userData.name,
            id: this.state.id,
            date: moment(this.state.date).format("YYYY-MM-DD"),
            start: this.state.start,
            end: this.state.end,
            pause: this.state.pause,
            bezStd: this.state.bezStd,
            fahrer: this.state.fahrer,
            baustelle: this.checkBaustelle(),
          };
          let updateObject = {
            id: this.state.id,
            datum: moment(this.state.date).format("YYYY-MM-DD"),
            beginn: this.state.start ? this.state.start : "",
            ende: this.state.end ? this.state.end : "",
            pausen: this.state.pause ? this.state.pause : "",
            bezStd: this.state.bezStd ? this.state.bezStd : "",
            fahrer: this.state.fahrer ? this.state.fahrer : "",
            baustelle: this.checkBaustelle(),
          };
          this.props.changeStunden(updateObject);
          $.ajax({
            url: BaseUrl + "api-seyband-stunden-app/stundenbericht/updateStundenbericht.php",
            type: "POST",
            dataType: "text",
            data: JSON.stringify(stundenUpdate),
            success: function (response) {
              this.props.toggle();
            }.bind(this),
            error: function (xhr, resp, text) {},
          });
        }
      }
    });
  };
  normalizeDate = (datum) => {
    let normalizeDate = datum.split("-");
    normalizeDate = normalizeDate[2] + "." + normalizeDate[1] + "." + normalizeDate[0];
    return normalizeDate;
  };
  render() {
    return (
      <Modal toggle={this.props.toggle} isOpen={this.props.modal}>
        <ModalBody>
          <div className="modal-header">
            <h3>
              <FontAwesomeIcon icon={faEdit} />
              &nbsp; Stundenbericht bearbeiten?
            </h3>
            <button className="close" onClick={this.props.toggle}>
              {" "}
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
          <div className="stunden-input">
            <div className="row  ">
              <div className="col-md-4 col-sm-4 col-12">
                <div className="absolute-p">
                  <p>Datum</p>
                  <DatePicker name="date" selected={this.state.date} onChange={this.handleDate} dateFormat="dd.MM.yyyy" locale={de} withPortal />
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="absolute-p">
                  <p>Beginn</p>
                  <input disabled={this.state.disableInput} value={this.state.start} name="start" type="time" onChange={this.onChange} />
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="absolute-p">
                  <p>Ende</p>
                  <input disabled={this.state.disableInput} value={this.state.end} name="end" type="time" onChange={this.onChange} />
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="absolute-p">
                  <p>Pause</p>
                  <input disabled={this.state.disableInput} value={this.state.pause} name="pause" type="time" onChange={this.onChange} />
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="absolute-p">
                  <p>Bez. Std.</p>
                  <select value={this.state.bezStd} onChange={this.onChange} name="bezStd">
                    <option value="Keine">Keine</option>
                    <option value="Urlaub">Urlaub</option>
                    <option value="Feiertag">Feiertag</option>
                    <option value="Krank">Krank</option>
                    <option value="Kurzarbeit">Kurzarbeit</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-6">
                <div className="absolute-p">
                  <p>Fahrer</p>
                  <select disabled={this.state.disableInput} value={this.state.fahrer} onChange={this.onChange} name="fahrer">
                    <option value="nein">Nein</option>
                    <option value="firmen-Kfz">firmen-Kfz</option>
                    <option value="privat-Kfz">privat-Kfz</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12 col-xs-12 col-12">
                <div className="absolute-p">
                  <p>Baustelle</p>
                  <input disabled={this.state.disableInput} value={this.state.baustelle} onChange={this.onChange} name="baustelle" type="text" />
                </div>
                <div className="absolute-p">
                  <p>akutelle Baustellen</p>

                  <select disabled={this.state.disableInput} value={this.state.baustelleBv} onChange={this.onChange} name="baustelleBv">
                    <option value="">-</option>
                    {this.props.bv.map((bv, i) => (
                      <option key={i} value={bv.bv}>
                        {bv.bv}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className={this.state.errorBv ? "col-12 show error " : "hide"}>
                <p>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  &nbsp; Bitte nur eine Baustelle ausfüllen!
                </p>
              </div>
              <div className={this.state.errorInput ? "col-md-12 show error " : "hide"}>
                {this.state.emptyRequired.map((name, i) => {
                  return (
                    <p key={i}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      &nbsp;
                      {name} darf nicht leer sein!
                    </p>
                  );
                })}
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <button onClick={this.updateStundenbericht} className="save-button">
                  Ändern <FontAwesomeIcon icon={faSave} />
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default StundenberichtModal;
