import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
   import StundenberichtModal from "../stundenbericht/stundenberichtModal.js";
 import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
 import { registerLocale } from "react-datepicker";
 import moment from "moment";
 
registerLocale("de", de);
 
class StundenberichtListe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      date: "",
      start: "",
      end: "",
      pause: "",
      bezStd: "-",
      fahrer: "-",
      baustelle: "",
      stunden: this.props.stunden,
      toggle: false,
      bv: this.props.bv
    };
  }
  componentDidMount = () => {
     if(sessionStorage.getItem){
     let name = JSON.parse(sessionStorage.getItem("userData"));
     name = name.userData.name.split("_");
     let fName = name[0].charAt(0).toUpperCase() + name[0].slice(1);
     let lName = name[1].charAt(0).toUpperCase() + name[1].slice(1);
     this.setState({
       firstname: fName,
       lastname: lName,
     });
    }
    else{
 
    }
 
  };
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
 
  generateModal = (
    idGen,
    datumGen,
    startGen,
    endGen,
    pauseGen,
    bezStdGen,
    fahrerGen,
    baustelleGen
  ) => e => {
    e.preventDefault();
     this.setState({
      modal: !this.state.modal,
      id: idGen,
      date: moment(datumGen).toDate(),
      start: startGen,
      end: endGen,
      pause: pauseGen,
      bezStd: bezStdGen,
      fahrer: fahrerGen,
      baustelle: baustelleGen
    });
  };
  toggle = () => {
     this.setState({
       modal: !this.state.modal
     });
  }
  addZero = number => {
    if(parseInt(number).length <= 1){
      return "0"+number;
    }
    else return number;
  }
 convertDate = date => {
   let help = "";
    if(date.includes(".")){
      help = date.split(".");
      help = help[2] + "-" + this.addZero(help[1]) + "-" + this.addZero((help[0]));
      return help;
    }
    else return date;
 } 
 
  render() {
    return (
      <ul className="stundenliste">
        <li className={this.props.stunden.length > 0 ? " row header-li" : "hide  "}>
          <div className="col-md-2">
            <p>Datum</p>
          </div>
          <div className="col-md-1">
            <p>Beginn</p>
          </div>
          <div className="col-md-1">
            <p>Ende</p>
          </div>
          <div className="col-md-1">
            <p>Pausen</p>
          </div>
          <div className="col-md-1">
            <p>Bez.Std.</p>
          </div>
          <div className="col-md-1">
            <p>Fahrer</p>
          </div>
          <div className="col-md-5">
            <p>Baustelle</p>
          </div>
        </li>
        {this.props.stunden
          .sort((a, b) => a.datum < b.datum)
          .map((item, idx) => {
            return (
              <li
                key={idx}
                onClick={this.generateModal(
                  item.id,
                  moment(this.convertDate(item.datum)).format("YYYY-MM-DD"),
                  item.beginn,
                  item.ende,
                  item.pausen,
                  item.bezStd,
                  item.fahrer,
                  item.baustelle
                )}
                className="row small-p"
              >
                <div className="col-2">{moment(this.convertDate(item.datum)).format("DD.MM.YYYY")}</div>
                <div className="col-1">
                  <p>Beginn</p>
                  {item.beginn}
                </div>
                <div className="col-1">
                  <p>Ende</p>
                  {item.ende}
                </div>
                <div className="col-1">
                  {" "}
                  <p>Pausen</p>
                  {item.pausen}
                </div>
                <div className="col-1">
                  <p>Bez.Std.</p>
                  {item.bezStd}
                </div>
                <div className="col-1">
                  <p>Fahrer</p>
                  {item.fahrer}
                </div>
                <div className="col-4">
                  <p>Baustelle</p>
                  {item.baustelle}
                </div>
              </li>
            );
          })}
        {this.state.modal ? (
          <StundenberichtModal
            modal={this.state.modal}
            toggle={this.toggle}
            id={this.state.id}
            date={this.state.date}
            start={this.state.start}
            end={this.state.end}
            pause={this.state.pause}
            bezStd={this.state.bezStd}
            fahrer={this.state.fahrer}
            baustelle={this.state.baustelle}
            changeStunden={this.props.changeStunden}
            bv={this.props.bv}
          />
        ) : (
          ""
        )}
      </ul>
    );
  }
}
export default StundenberichtListe;
