import React, { Component } from "react";
import Head from "../parts/head.js";
import Footer from "../parts/footer.js";
import "../css/index.css";
import "../css/bootstrap.css";
import BaseUrl from "../config/config.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSave, faUser, faCheckSquare, faExclamationCircle, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
var $ = require("jquery");
var md5 = require("md5");

class UserAccount extends Component {
  constructor() {
    super();
    this.state = {
      firstname: "",
      lastname: "",
      oldPassword: "",
      newPassword: "",
      email: "",
      regKey: "",
      sendMail: false,
      updateSuccess: false,
      updateError: false,
      existsError: false,
      errorRegKey: false,
      showOldPassword: false,
      showNewPassword: false,
      errorText: "",
      changePassword: false,
     };
  }
  componentDidMount = () => {
        let title = document.title;
        document.title = "Account - " + title;
     if (sessionStorage.getItem("userData")) {
      Promise.all([this.creatName()]).then(() => {
        this.loadUserInfo(JSON.parse(sessionStorage.getItem("userData")).userData.name);
      });
    }
  };
  creatName = () => {
    let name = JSON.parse(sessionStorage.getItem("userData"));
    name = name.userData.name.split("_");
    let fName = name[0].charAt(0).toUpperCase() + name[0].slice(1);
    let lName = name[1].charAt(0).toUpperCase() + name[1].slice(1);
    this.setState({
      firstname: fName,
      lastname: lName,
    });
  };
  loadUserInfo = (name) => {
    return fetch(BaseUrl + "api-seyband-stunden-app/user/readUserData.php?name=" + name)
      .then((response) => response.json())
      .then((responseJson) => {
        return this.setState({
          email: responseJson.records[0][0].email,
          sendMail: responseJson.records[0][0].sendMail === "0" ? false : true,
        });
      })
      .catch((error) => {});
  };
  updateUser = (e) => {
    e.preventDefault();

    let combineNames = this.state.firstname + "_" + this.state.lastname;
    let user = {
      name: combineNames.toLocaleLowerCase(),
      email: this.state.email,
      sendMail: this.state.sendMail ? 1 : 0,
      changePassword: this.state.changePassword ? 1 : 0,
      oldPassword: md5(this.state.oldPassword),
      newPassword: md5(this.state.newPassword),
    };
    $.ajax({
      url: BaseUrl + "api-seyband-stunden-app/user/updateUserData.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(user),
      success: function (response) {
        if (response === "true") {
          if(this.state.oldPassword && this.state.newPassword){
            this.waitForRedirect();
          }
          this.setState({
            updateSuccess: true,
            updateError: false,
          });
        } else if (response === "old wrong") {
          this.setState({
            updateError: true,
            updateSuccess: false,
            errorText: "Das Alte Passwort ist falsch!",
           });
        } else if (response === "old new same") {
          this.setState({
            updateError: true,
            updateSuccess: false,
            errorText: "Das aktuelle und neue Passwort dürfen nicht gleich sein!",
           });
        }
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
        updateSuccess: false
      });
    }
    if (e.target.name === "changePassword") {
      this.setState({
        newPassword: "",
        oldPassword: "",
      });
    }
  };

  clickShowOldPw = (e) => {
    e.preventDefault();
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    });
  };
  clickShowNewPw = (e) => {
    e.preventDefault();
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };
  waitForRedirect = () => {
 
    setTimeout(
      function () {
        //Start the timer
        sessionStorage.removeItem("userData");
        this.setState({ redirectLogin: true }); //After 1 second, set render to true
      }.bind(this),
      4500
    );
  };
  render() {
    if (sessionStorage.getItem("userData") && !this.state.redirectLogin) {
      return (
        <div>
          <Head disableLogin={false} />
          <section className="userData">
            <div className="text-center  container">
              <h1>
                <FontAwesomeIcon icon={faUser} />
                &nbsp;Account
              </h1>
              <div className="row  max-width-login">
                <form onSubmit={this.updateUser} autoComplete="off">
                  <div className="absolute-p">
                    <p>Vorname</p>
                    <input value={this.state.firstname} disabled type="text" name="firstname" onChange={this.onChange} />
                  </div>
                  <div className="absolute-p">
                    <p>Nachname</p>
                    <input disabled value={this.state.lastname} type="text" name="lastname" onChange={this.onChange} />
                  </div>
                  <div className="absolute-p">
                    <p>Email</p>
                    <input autoComplete="new-password" value={this.state.email} type="email" name="email" onChange={this.onChange} />
                  </div>
                  <div className="absolute-p">
                    <label>
                      <input type="checkbox" name="sendMail" onChange={this.onChange} checked={this.state.sendMail} />
                      Stundenbericht an Emailadresse senden?
                    </label>
                  </div>
                  <div className="absolute-p">
                    <label>
                      <input type="checkbox" name="changePassword" onChange={this.onChange} checked={this.state.changePassword} />
                      Passwort Ändern
                    </label>
                  </div>
                  <div className={this.state.changePassword ? "show info text-left" : "hide"}>
                    <FontAwesomeIcon icon={faInfoCircle} /> Erneutes Einloggen erforderlich
                  </div>
                  <div className="absolute-p">
                    <p>aktuelles Passwort</p>
                    <input
                      disabled={!this.state.changePassword}
                      autoComplete="new-password"
                      value={this.state.oldPassword}
                      type={this.state.showOldPassword ? "text" : "password"}
                      name="oldPassword"
                      onChange={this.onChange}
                    />
                    <button name="showOldPassword" type="button" className="showPassword" onClick={this.clickShowOldPw}>
                      {this.state.showOldPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                    </button>
                  </div>
                  <div className="absolute-p">
                    <p>neues Passwort</p>
                    <input
                      disabled={!this.state.changePassword}
                      autoComplete="new-password"
                      value={this.state.newPassword}
                      type={this.state.showNewPassword ? "text" : "password"}
                      name="newPassword"
                      onChange={this.onChange}
                    />
                    <button name="showNewPassword" type="button" className="showPassword" onClick={this.clickShowNewPw}>
                      {this.state.showNewPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                    </button>
                  </div>
                  <div className="row">
                    <div className={this.state.updateSuccess ? "show success col-12" : "hide"}>
                      <FontAwesomeIcon icon={faCheckSquare} />
                      <p>Ihre Daten wurde geändert</p>
                      <p className={this.state.changePassword ? "show" : "hide"}>Sie werden zu Login-Seite weitergeleitet.</p>
                    </div>
                    <div className={this.state.updateError ? "show error col-12" : "hide"}>
                      <FontAwesomeIcon icon={faExclamationCircle} />
                      <p>{this.state.errorText}</p>
                    </div>
                  </div>

                  <button className="login-button" type="submit">
                    Infos ändern <FontAwesomeIcon icon={faSave} />
                  </button>
                </form>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      );
    } else {
      return <Redirect to={"/"} />;
    }
  }
}
export default UserAccount;
