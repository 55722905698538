import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "./postdata";
import "../css/index.css";
import "../css/bootstrap.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faExclamationTriangle, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import Footer from "../parts/footer.js";
var md5 = require("md5");

class Login extends Component {
  constructor() {
    super();
    this.state = {
      firstname: "",
      lastname: "",
      password: "",
      redirectToReferrer: false,
      error: false,
      showPw: false,
    };
  }

  login = () => {
    if (this.state.firstname && this.state.lastname && this.state.password) {
      let loginData = {
        firstname: this.state.firstname.trim(),
        lastname: this.state.lastname.trim(),
        password: md5(this.state.password),
      };
      PostData("login", loginData).then((result) => {
        let responseJson = result;
        if (responseJson.userData) {
          sessionStorage.setItem("userData", JSON.stringify(responseJson));
          this.setState({ redirectToReferrer: true });
        } else {
          this.setState({
            error: true,
          });
        }
      });
    }
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  enter = (e) => {
    e.preventDefault();
    if (e.key === "enter") {
      this.login();
    }
  };
  clickShowPw = (e) => {
    e.preventDefault();
    console.log(e);
    if (e.key === "enter") {
      this.enter();
    } else {
      this.setState({
        showPw: !this.state.showPw,
      });
    }
  };
  render() {
    if (this.state.redirectToReferrer || sessionStorage.getItem("userData")) {
      if (JSON.parse(sessionStorage.getItem("userData")).userData.name === "admin_admin") {
        return <Redirect to={"/admin"} />;
      } else return <Redirect to={"/stundenbericht"} />;
    }

    return (
      <div>
        <section className="login">
          <div className="text-center  container">
            <img src={require("../img/logo.png")} alt="" />
            <h1>
              Login Stundenzettel/
              <br id="hide-br-big" />
              Urlaubsantrag
            </h1>
            <div className="row  max-width-login">
              <form onSubmit={this.enter}>
                <div className="absolute-p">
                  <p>Vorname</p>
                  <input required type="text" name="firstname" onChange={this.onChange} />
                </div>
                <div className="absolute-p">
                  <p>Nachname</p>
                  <input required type="text" name="lastname" onChange={this.onChange} />
                </div>
                <div className="absolute-p">
                  <p>Passwort</p>
                  <input required type={this.state.showPw ? "text" : "password"} name="password" onChange={this.onChange} />
                  <button type="button" className="showPassword" onClick={this.clickShowPw}>
                    {this.state.showPw ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                  </button>
                </div>
                <div className={this.state.error ? "col-12 show error " : "hide"}>
                  <p>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    &nbsp; Benutzername oder Passwort falsch
                  </p>
                </div>
                <button className="login-button" type="submit" onClick={this.login}>
                  Einloggen <FontAwesomeIcon icon={faSignInAlt} />
                </button>
              </form>

              <div className="col-md-12  ">
                <Link to="/registrieren">Noch nicht Registriert? Bitte hier klicken</Link>
              </div>
              <div className="col-md-12  ">
                <Link to="/passwort-reset">Passwort vergessen? Erneut zuschicken!</Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Login;
