import React, { Component } from "react";
import Head from "../parts/head.js";
import Footer from "../parts/footer.js";
import "../css/index.css";
import "../css/bootstrap.css";
import BaseUrl from "../config/config.js";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faCheckSquare, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
var $ = require("jquery");
var md5 = require("md5");

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordNew: "",
      passwordCheck: "",
      email: "",
      success: false,
      errorMessage: false,
      errorRegKey: false,
      redirectLogin: false,
      errorPassword: false,
      message: "",
      showPassword: false,
      showPasswordCheck: false
    };
  }
  componentWillMount = () => {};
  componentDidMount = () => {
         document.title = "Neues Passwort - Seyband Stunden App";
    if (
      this.props.match.params.key === undefined ||
      this.props.match.params.name === undefined ||
      this.props.match.params.email === undefined ||
      this.props.match.params.action === undefined
    ) {
      this.setState({
        redirectLogin: true,
      });
    } else {
      this.checkKeyExpired();
    }
  };
  checkKeyExpired = () => {
    return fetch(
      BaseUrl +
        "api-seyband-stunden-app/user/checkKeyExpired.php?name=" +
        this.props.match.params.name +
        "&email=" +
        this.props.match.params.email +
        "&key=" +
        this.props.match.params.key
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "false") {
          this.setState({
            errorMessage: true,
          });
        }
      })
      .catch((error) => {});
  };

  createNewPassword = (e) => {
    e.preventDefault();
    if (this.state.passwordNew !== this.state.passwordCheck) {
      this.setState({
        errorPassword: true,
      });
    } else {
      this.setState({
        errorPassword: false,
      });
      let data = {
        password: md5(this.state.passwordNew),
        name: this.props.match.params.name,
        email: this.props.match.params.email,
        key: this.props.match.params.key,
      };
      $.ajax({
        url: BaseUrl + "api-seyband-stunden-app/user/updateNewPassword.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(data),
        success: function (response) {
          if (response === "true") {
            this.setState({
              success: true,
              errorMessage: false,
            });
            this.waitForRedirect();
          } else {
            this.setState({
              errorMessage: true,
              success: false,
            });
          }
        }.bind(this),
        error: function (xhr, resp, text) {},
      });
    }
  };
  waitForRedirect = () => {
    setTimeout(
      function () {
        //Start the timer
        this.setState({ redirectLogin: true }); //After 1 second, set render to true
      }.bind(this),
      4500
    );
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  clickShowPw = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };
  clickShowPwChecked = (e) => {
    e.preventDefault();
    this.setState({
      showPasswordCheck: !this.state.showPasswordCheck,
    });
  };
  render() {
    if (this.state.redirectLogin) {
      return <Redirect to={"/"} />;
    }

    return (
      <div>
        <Head disableLogin={true} />
        <section className="new-password">
          <div className="text-center  container">
            <h1>Neues Passwort eingeben</h1>
            <hr />
            <div className="row  max-width-login">
              <form onSubmit={this.createNewPassword} autoComplete="nope">
                <div className="absolute-p">
                  <p>Passwort</p>
                  <input
                    autoComplete="nope"
                    required
                    value={this.state.password}
                    type={this.state.showPassword ? "text" : "password"}
                    name="passwordNew"
                    onChange={this.onChange}
                  />
                  <button name="showPassword" type="button" className="showPassword" onClick={this.clickShowPw}>
                    {this.state.showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                  </button>
                </div>
                <div className="absolute-p">
                  <p>Passwort wiederholen</p>
                  <input
                    autoComplete="nope"
                    required
                    value={this.state.passwordCheck}
                    type={this.state.showPasswordCheck ? "text" : "password"}
                    name="passwordCheck"
                    onChange={this.onChange}
                  />
                  <button name="showPasswordCheck" type="button" className="showPassword" onClick={this.clickShowPwChecked}>
                    {this.state.showPasswordCheck ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                  </button>
                </div>
                <span className={this.state.success ? "show success" : "hide"}>
                  <FontAwesomeIcon icon={faCheckSquare} />
                  Ihr Passwort wurde geändert. Sie werden gleich zur Login-Seite weitergeleitet.
                </span>
                <span className={this.state.errorMessage ? "show error" : "hide"}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  Der Link ist ungültig, abgelaufen oder Sie haben Ihr Passwort bereits geändert.
                </span>
                <span className={this.state.errorPassword ? "show error" : "hide"}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  Die Passwörter stimmen nicht überein. Bitte überprüfen Sie Ihre Eingabe.
                </span>
                <button disabled={this.state.errorMessage} className="login-button" type="submit">
                  Jetzt Ändern
                </button>
              </form>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default NewPassword;
