import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
import Head from "../parts/head.js";
import Footer from "../parts/footer.js";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCheckSquare, faPaperPlane, faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import BaseUrl from "../config/config.js";
import { registerLocale } from "react-datepicker";
 import { Redirect } from "react-router-dom";
registerLocale("de", de);
var $ = require("jquery");

class Urlaubsantrag extends Component {
  constructor() {
    super();
    this.state = {
      firstname:"",
      lastname:"",
      dateFrom: "",
      dateTo: "",
      successSend: false
    };
  }
  componentDidMount = () => {
        document.title = "Urlaubsantrag  - Seyband Stunden App";
    if (sessionStorage.getItem("userData")) {
    Promise.all([this.creatName()]).then(() => {});
    }
  };
  creatName = () => {
    let name = JSON.parse(sessionStorage.getItem("userData"));
    name = name.userData.name.split("_");
    let fName = name[0].charAt(0).toUpperCase() + name[0].slice(1);
    let lName = name[1].charAt(0).toUpperCase() + name[1].slice(1);
    this.setState({
      firstname: fName,
      lastname: lName
    });
  };
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDateFrom = d => {
    this.setState({
      dateFrom: d
    });
  };
  handleDateTo = d => {
    this.setState({
      dateTo: d
    });
  };
  sendUrlaubsantrag = e => {
    e.preventDefault();
     let name = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo
    };
    $.ajax({
      url:
        BaseUrl + "api-seyband-stunden-app/urlaubsantrag/sendUrlaubsantrag.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(name),
      success: function(response) {
        if (response === "gesendet") {
          this.setState({
            dateFrom: "",
            dateTo: ""
          });
          this.showSuccessSend("successSend");
        }
      }.bind(this),
      error: function(xhr, resp, text) {}
    });
  };
  showSuccessSend = what => {
    this.setState({
      [what]: true
    });
    setTimeout(() => {
      this.setState({
        [what]: false
      });
    }, 8000);
  };
  render() {
   if(sessionStorage.getItem("userData")){
    return (
      <div>
        <Head disableLogin={false} />
        <section className="urlaubsantrag">
          <div className="menu-links text-center">
            <h1>
              {" "}
              <FontAwesomeIcon icon={faPlaneDeparture} />
              &nbsp; Urlaubsantrag
            </h1>
            <h2>
              - {this.state.firstname} {this.state.lastname} -
            </h2>
          </div>
          <div className="container">
            <form onSubmit={this.sendUrlaubsantrag}>
              <div className="urlaubsantrag-input">
                <div className="row  ">
                  <div className="col-12">
                    <div className="absolute-p">
                      <p>Vorname</p>
                      <input value={this.state.firstname} disabled type="text" name="firstname" onChange={this.onChange} />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="absolute-p">
                      <p>Nachname</p>
                      <input disabled value={this.state.lastname} type="text" name="lastname" onChange={this.onChange} />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <h3>Ich beantrage Urlaub </h3>
                  </div>
                  <div className="col-12">
                    <div className="absolute-p">
                      <p>vom (erster Tag)</p>
                      <DatePicker
                        name="dateFrom"
                        selected={this.state.dateFrom}
                        onChange={this.handleDateFrom}
                        dateFormat="dd.MM.yyyy"
                        locale={de}
                        withPortal
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="absolute-p">
                      <p>bis (letzter Tag)</p>
                      <DatePicker
                        name="dateTo"
                        selected={this.state.dateTo}
                        onChange={this.handleDateTo}
                        dateFormat="dd.MM.yyyy"
                        locale={de}
                        withPortal
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <p className={this.state.successSend ? "show success" : "hide"}>
                      <FontAwesomeIcon icon={faCheckSquare} />
                      Ihr Urlaubsantrag wurde verschickt!
                    </p>
                  </div>
                  <div className="col-12">
                    <button disabled={this.state.dateFrom && this.state.dateTo ? false : true} type="submit" className="save-button">
                      Urlaubsantrag senden <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    );
                    }
        else{
      return <Redirect to={"/"}/>
    }
  }
}
export default Urlaubsantrag;
