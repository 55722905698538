import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faSignInAlt, faBars } from "@fortawesome/free-solid-svg-icons";
class Head extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableLogin: this.props.disableLogin
    };
  }
  logout = () =>{
    sessionStorage.removeItem("userData");
  }
  render() {
    return (
      <Navbar bg="light" expand="lg">
        <div className="header-fix">
          <Navbar.Brand href="/">
            <img src={require("../img/logo.png")} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <FontAwesomeIcon icon={faBars} />
          </Navbar.Toggle>
        </div>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-center">
            <NavLink className={this.state.disableLogin ? "show" : "hide"} to="/">
              <FontAwesomeIcon icon={faSignInAlt} />
              &nbsp; Login
            </NavLink>
            <NavLink activeClassName="is-active" className={this.state.disableLogin ? "hide" : "show"} to="/stundenbericht">
              Stundenbericht
            </NavLink>
            <NavLink activeClassName="is-active" className={this.state.disableLogin ? "hide" : "show"} to="/urlaubsantrag">
              Urlaubsantrag
            </NavLink>
            <NavLink activeClassName="is-active" className={this.state.disableLogin ? "hide" : "show"} to="/betriebsanweisungen">
              Betriebsanweisungen
            </NavLink>
            <NavLink activeClassName="is-active" className={this.state.disableLogin ? "hide" : "show"} to="/account">
              Account
            </NavLink>
            <NavLink className={this.state.disableLogin ? "hide" : "show"} onClick={this.logout} to="/">
              Logout <FontAwesomeIcon icon={faSignOutAlt} />
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default Head;
