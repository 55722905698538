import React from "react";
import ReactDOM from "react-dom";
import "./css/App.css";
import Login from "./services/login.js";
import Register from "./services/register.js";
import Account from "./user/userAccount.js";
import Stundenbericht from "./stundenbericht/stundenbericht.js";
import Urlaubsantrag from "./urlaubsantrag/urlaubsantrag.js";
import Betriebsanweisungen from "./betriebsanweisungen/betriebsanweisungen.js";
import NewPassword from "./services/new-password.js";
import PasswortReset from "./services/passwort-reset.js";
import Admin from "./admin/admin";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";

const login = () => {
  return <Login />;
};
const register = () => {
  return <Register />;
};
const stundenbericht = () => {
  return <Stundenbericht />;
};
const account = () => {
  return <Account />;
};
const urlaubsantrag = () => {
  return <Urlaubsantrag />;
};
const betriebsanweisungen = () => {
  return <Betriebsanweisungen />;
};
const resetpasswort = () => {
  return <PasswortReset />;
};
const admin = () => {
  return <Admin />;
};
ReactDOM.render(
  <Router basename="/">
    <React.Fragment>
      <Route exact path="/" component={login} />
      <Route exact path="/registrieren" component={register} />
      <Route exact path="/stundenbericht" component={stundenbericht} />
      <Route exact path="/account" component={account} />
      <Route exact path="/urlaubsantrag" component={urlaubsantrag} />
      <Route exact path="/betriebsanweisungen" component={betriebsanweisungen} />
      <Route exact path="/passwort-reset" component={resetpasswort} />
      <Route exact path="/admin" component={admin} />
      <Route exact path="/neues-passwort/:key?/:name?/:email?/:action?" render={(props) => <NewPassword {...props} />} />
    </React.Fragment>
  </Router>,
  document.getElementById("root")
);
