import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faPaperPlane, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
  import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalBody } from "reactstrap";
 registerLocale("de", de);

 
class SendModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
 
    };
  }
  componentDidMount = () => {
 
  };
 
 
  render() {
    return (
      <Modal toggle={this.props.toggle} isOpen={this.props.modal}>
        <ModalBody>
          <div className="modal-header">
            <h3>
              <FontAwesomeIcon icon={faQuestionCircle} />
              &nbsp; Stundenbericht senden
            </h3>
            <button className="close" onClick={this.props.toggle}>
              {" "}
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
          <div className="send-list">
            <div className="row  ">
              <div className="col-12">
                <p>
                  Wenn Sie den Stundenbericht an die Firma senden, leert sich Ihre Übersichtsliste. Sollte Sie in Ihrem Account die Option
                  "Stundenbericht an Emailadresse senden" aktiviert haben, erhalten Sie eine Kopie zugeschickt!
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <button onClick={this.props.toggle} className="abb-button">
                  Abbrechen <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </div>
              <div className="col-6">
                <button onClick={this.props.send} className="save-button">
                  Senden <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default SendModal;
