import React, { Component } from "react";
import Head from "../parts/head.js";
import Footer from "../parts/footer.js";
import "../css/index.css";
import "../css/bootstrap.css";
import BaseUrl from "../config/config.js";
import { Redirect } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
var $ = require("jquery");

class PasswortReset extends Component {
  constructor() {
    super();
    this.state = {
      firstname: "",
      lastname: "",
      password: "",
      email: "",
      regKey: "",
      resetSuccess: false,
      errorMessage: false,
      errorRegKey: false,
      redirectLogin: false,
      errorAlreadySend: false,
    };
  }
  componentDidMount = () => {
        document.title = "Passwort anfordern  - Seyband Stunden App" ;
  }
  resetPasswort = (e) => {
    e.preventDefault();
    let name = this.state.firstname + "_" + this.state.lastname;
    name = name.toLocaleLowerCase();

    return fetch(BaseUrl + "api-seyband-stunden-app/user/readUserDataReset.php?name=" + name + "&email=" + this.state.email)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson !== false) {
          this.generateResetPassword(name, this.state.email);
        } else {
          this.setState({
            errorMessage: true,
          });
        }
      })
      .catch((error) => {});
  };
  generateResetPassword = (name, email) => {
    let data = {
      name: name,
      email: email,
    };
    $.ajax({
      url: BaseUrl + "api-seyband-stunden-app/user/generatePasswordReset.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(data),
      success: function (response) {
        if (response === "already send") {
          this.setState({
            errorAlreadySend: true,
            errorMessage: false,
            resetSuccess: false,
          });
        } else {
          this.setState({
            errorAlreadySend: false,
            errorMessage: false,
            resetSuccess: true,
          });
        }

        this.waitForRedirect();
      }.bind(this),
      error: function (xhr, resp, text) {},
    });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  waitForRedirect = () => {
  setTimeout(
      function () {
        //Start the timer
         this.setState({ redirectLogin: true }); //After 1 second, set render to true
      }.bind(this),
      5500
    );  
  };
  render() {
    if (this.state.redirectLogin) {
      return <Redirect to={"/"} />;
    }

    return (
      <div>
        <Head disableLogin={true} />
        <section className="password-reset">
          <div className="text-center  container">
            <h1>Passwort anfordern</h1>
            <hr />
            <div className="row  max-width-login">
              <form onSubmit={this.resetPasswort}>
                <div className="absolute-p">
                  <p>Vorname</p>
                  <input value={this.state.firstname} required type="text" name="firstname" onChange={this.onChange} />
                </div>
                <div className="absolute-p">
                  <p>Nachname</p>
                  <input required value={this.state.lastname} type="text" name="lastname" onChange={this.onChange} />
                </div>
                <div className="absolute-p">
                  <p>Email</p>
                  <input required value={this.state.email} type="email" name="email" onChange={this.onChange} />
                </div>
                <span className={this.state.errorAlreadySend ? "show error" : "hide"}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  Eine Email zum Zurücksetzen des Passworts wurde bereits an diese Email verschickt.
                </span>
                <span className={this.state.resetSuccess ? "show success" : "hide"}>
                  <FontAwesomeIcon icon={faCheckSquare} />
                  Wir haben eine Email an Ihre Emailadresse geschickt. Setzen Sie mit hilfe des Links in der Email Ihr Passwort zurück!
                  <br />
                  <br />
                  <b>
                    {" "}
                    <FontAwesomeIcon icon={faInfoCircle} /> Bitte auch im SPAM-Ordner nachschauen!
                  </b>
                </span>
                <span className={this.state.errorMessage ? "show error" : "hide"}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  Der Benutzer und die Emailadresse stimmen nicht überein!
                </span>
                <button className="login-button" type="submit">
                  Jetzt Anfordern
                </button>
              </form>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default PasswortReset;
