import React, { Component } from "react";
import "../css/index.css";
import "../css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import BaseUrl from "../config/config.js";
  import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
 import { registerLocale } from "react-datepicker";
  import { Redirect } from "react-router-dom";
 import HeadAdmin from "../parts/headAdmin.js";
 import AdminModal from "../admin/adminModal.js";
 import Footer from "../parts/footer.js";

registerLocale("de", de);
 
class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      mitarbeiter: [],
      modal: false,
      vorname:"",
      nachname:"",
      emai:"",
      sendMail: ""
    };
  }
  componentDidMount = () => {
    document.title = "Adminbereich -  Seyband Stunden App";
    if (JSON.parse(sessionStorage.getItem("userData")).userData.name === "admin_admin") {
      this.loadMitarbeiter();
    } else {
      this.setState({
        redirect: true,
      });
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loadMitarbeiter = () => {
    return fetch(BaseUrl + "api-seyband-stunden-app/admin/readMitarbeiter.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          mitarbeiter: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };
  prettierName = (name) => {
    let help = "";
    help = name.split("_");
    if (help[1]) {
      help = help[0].charAt(0).toUpperCase() + help[0].slice(1) + " " + help[1].charAt(0).toUpperCase() + help[1].slice(1);
      return help;
    } else return help[0].charAt(0).toUpperCase() + help[0].slice(1);
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  generateModal = (id, vorname, nachname, email, sendMail) => (e) => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal,
      id: id,
       vorname: vorname,
      nachname: nachname,
      email: email ? email: "",
      sendMail: sendMail
  });
}
  refreshArray = (id, vorname, nachname, email, sendMail) => {
    let find = this.state.mitarbeiter.findIndex((element) => element.id === id);
    let newArray = [...this.state.mitarbeiter];
    newArray[find] = { ...newArray[find], id: id, vorname: vorname, nachname:nachname, email:email, sendMail: sendMail ?"1" : "0" };
    this.setState({
      mitarbeiter: newArray,
    });
  }
  refreshArrayDelte = (id) => {
    this.setState({
      mitarbeiter: this.state.mitarbeiter.filter((element, i) => element.id !== id)
    })
  }
  render() {
    if (!this.state.redirect) {
      return (
        <div>
          <HeadAdmin disableLogin={false} />
          <section>
            <ul className="admin-liste">
              <div className="row list-header">
                <div className="col">
                  <p>Name</p>
                </div>
                <div className="col">
                  <p>Email</p>
                </div>
                <div className="col">
                  <p>Bericht senden?</p>
                </div>
              </div>

              {this.state.mitarbeiter.map((item, i) => {
                return (
                  <li
                    onClick={this.generateModal(item.id, item.name.split("_")[0], item.name.split("_")[1], item.email, item.sendMail)}
                    key={i}
                    className="row"
                  >
                    <div className="col"> {this.prettierName(item.name)}</div>
                    <div className="col"> {item.email ? item.email : "-"}</div>
                    <div className="col"> {item.sendMail === "1" ? <FontAwesomeIcon icon={faCheck} /> : "-"}</div>
                  </li>
                );
              })}
              {this.state.modal ? (
                <AdminModal
                  refreshArray={this.refreshArray}
                  refreshArrayDelte={this.refreshArrayDelte}
                  modal={this.state.modal}
                  toggle={this.toggle}
                  id={this.state.id}
                  vorname={this.state.vorname}
                  nachname={this.state.nachname ? this.state.nachname : ""}
                  email={this.state.email}
                  sendMail={this.state.sendMail}
                />
              ) : (
                ""
              )}
            </ul>
          </section>
          <Footer />
        </div>
      );
    } else {
      return <Redirect to={"/"} />;
    }
  }
}
export default Admin;
