import React, { Component } from "react";
import Head from "../parts/head.js";
import Footer from "../parts/footer.js";
import "../css/index.css";
import "../css/bootstrap.css";
import BaseUrl from "../config/config.js";
import { regKey } from "../config/config.js";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faExclamationCircle, faInfoCircle, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
var $ = require("jquery");
var md5 = require("md5");
class Register extends Component {
  constructor() {
    super();
    this.state = {
      firstname: "",
      lastname: "",
      password: "",
      passwordCheck: "",
      email: "",
      regKey: "",
      registerSuccess: false,
      existsError: false,
      errorRegKey: false,
      errorPassword: false,
      sendMail: false,
      redirectLogin: false,
      infoRegister: false,
      errorEmailSendCheck: false,
    };
  }
  componentDidMount = () => {
    document.title = "Registrieren - Seyband Stunen App";
  };
  registerUser = (e) => {
    e.preventDefault();
    if (this.state.sendMail && this.state.email === "") {
      this.setState({
        errorEmailSendCheck: true,
      });
    } else if (regKey !== this.state.regKey) {
      this.setState({
        errorRegKey: true,
      });
    } else if (this.state.password !== this.state.passwordCheck) {
      this.setState({
        errorPassword: true,
        errorRegKey: false,
      });
    } else {
      let combineNames = this.state.firstname.trim() + "_" + this.state.lastname.trim();
      let user = {
        name: combineNames.toLocaleLowerCase(),
        password: md5(this.state.password),
        email: this.state.email,
        sendMail: this.state.sendMail ? 1 : 0,
      };
      $.ajax({
        url: BaseUrl + "api-seyband-stunden-app/register/registerUser.php",
        type: "POST",
        dataType: "text",
        data: JSON.stringify(user),
        success: function (response) {
          if (response === "0") {
            this.setState({
              existsError: true,
              registerSuccess: false,
              errorPassword: false,
            });
          } else {
            this.setState({
              firstname: "",
              lastname: "",
              password: "",
              passwordCheck: "",
              email: "",
              regKey: "",
              errorRegKey: false,
              existsError: false,
              registerSuccess: true,
              errorPassword: false,
              errorEmailSendCheck: false,
            });
            this.waitForRedirect();
          }
        }.bind(this),
        error: function (xhr, resp, text) {},
      });
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    }
    if (e.target.type === "checkbox" && !e.target.checked && this.state.email === "") {
      this.setState({
        errorEmailSendCheck: false,
      });
    }
  };
  showInfoRegister = () => {
    this.setState({
      infoRegister: !this.state.infoRegister,
    });
  };
  clickShowPw = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };
  clickShowPwChecked = (e) => {
    e.preventDefault();
    this.setState({
      showPasswordCheck: !this.state.showPasswordCheck,
    });
  };
  waitForRedirect = () => {
    setTimeout(
      function () {
        //Start the timer
        this.setState({ redirectLogin: true }); //After 1 second, set render to true
      }.bind(this),
      4500
    );
  };
  render() {
    if (this.state.redirectLogin) {
      return <Redirect to={"/"} />;
    }
    return (
      <div>
        <Head disableLogin={true} />
        <section className="register">
          <div className="text-center  container">
            <h1>
              Registrieren Stundenzettel/
              <br id="hide-br-big" />
              Urlaubsantrag{" "}
            </h1>
            <div className="row  max-width-login">
              <form onSubmit={this.registerUser}>
                <div className="absolute-p">
                  <p>Vorname</p>
                  <input value={this.state.firstname} required type="text" name="firstname" onChange={this.onChange} />
                </div>
                <div className="absolute-p">
                  <p>Nachname</p>
                  <input required value={this.state.lastname} type="text" name="lastname" onChange={this.onChange} />
                </div>
                <div className="absolute-p">
                  <p>Email</p>
                  <input value={this.state.email} type="email" name="email" onChange={this.onChange} />
                </div>
                <div className="absolute-p">
                  <label>
                    <input type="checkbox" name="sendMail" onChange={this.onChange} checked={this.state.sendMail} />
                    Stundenbericht an Emailadresse senden?
                  </label>
                </div>
                <div className="absolute-p">
                  <p>Passwort</p>
                  <input
                    required
                    value={this.state.password}
                    type={this.state.showPassword ? "text" : "password"}
                    name="password"
                    onChange={this.onChange}
                  />
                  <button name="showPassword" type="button" className="showPassword" onClick={this.clickShowPw}>
                    {this.state.showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                  </button>
                </div>
                <div className="absolute-p">
                  <p>Passwort wiederholen</p>
                  <input
                    required
                    value={this.state.passwordCheck}
                    type={this.state.showPasswordCheck ? "text" : "password"}
                    name="passwordCheck"
                    onChange={this.onChange}
                  />
                  <button name="showPasswordCheck" type="button" className="showPassword" onClick={this.clickShowPwChecked}>
                    {this.state.showPasswordCheck ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                  </button>
                </div>
                <div className="absolute-p">
                  <p>Registrierungsschlüssel</p>
                  <input required value={this.state.regKey} type="text" name="regKey" onChange={this.onChange} />
                  <button name="infoRegister" type="button" className="infoRegister" onClick={this.showInfoRegister}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </button>
                </div>
                <div className={this.state.infoRegister ? "show register-info" : "hide"}>
                  <p>Der Registrierungsschlüssel wird einmalig zur Registrierung benötigt. Diesen erhalten Sie auf Anfrage bei Ihrem Arbeitgeber.</p>
                </div>
                <p className={this.state.errorEmailSendCheck ? "show error" : "hide"}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  &nbsp; Wenn Sie Ihren Stundenbericht zugeschickt bekommen wollen, müssen Sie eine gültige Emailadresse eingeben.
                </p>
                <p className={this.state.errorPassword ? "show error" : "hide"}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  &nbsp; Die Passwörter stimmen nicht überein. Bitte überprüfen Sie Ihre Eingabe.
                </p>
                <p className={this.state.errorRegKey ? "show error" : "hide"}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  &nbsp; Falscher Registrierungsschlüssel!
                </p>
                <p className={this.state.existsError ? "show error" : "hide"}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  &nbsp; Dieser Name ist bereits registriert!
                  <br />
                  <br />
                  Wenn Sie Ihr Passwort vergessen haben bitte folgenden Link nutzen:
                  <br />
                  <Link to="/passwort-reset">Passwort zurücksetzen</Link>
                </p>
                <p className={this.state.registerSuccess ? "show success" : "hide"}>
                  <FontAwesomeIcon icon={faCheckSquare} />
                  &nbsp; Ihre Registrierung war erfolgreich. Bitte gehen Sie zur Startseite zurück und loggen sich mit Ihren Daten ein.
                </p>
                <button className="login-button" type="submit">
                  Registrieren
                </button>
              </form>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Register;
